.searchBox {
  padding: 8px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
}

.searchBox input {
  width: 130px;
  margin-right: 8px;
}

.searchBox button {
  margin-right: 8px;
}

.authorList {
  margin: 0;
  padding: 0;
}

.visitedRow {
  background: rgba(24, 144, 255, 0.13);
}
